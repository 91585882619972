body {
    font-family: Arial, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
  
.container {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 2em;
    max-width: 600px;
    width: fit-content;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container input{
    margin-top: 10px;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
}
  
h1 {
    color: #333;
    text-align: center;
}
  
h2 {
    color: #555;
    margin-bottom: 10px;
}
  
p {
    color: #666;
}
  
ul {
    list-style-type: none;
    padding: 0;
}
  
li {
    background-color: #f9f9f9;
    margin: 5px 0;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

 li.incorrect {
    color: red;
 }

 li.correct {
    color: green;
 }
  
button {
    margin: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}
  
button:hover {
    background-color: #45a049;
}
  
textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: none;
    margin-top: 10px;
    font-size: 16px;
}
  
.response-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.response {
    flex: 1;
    margin: 10px;
}
  
.prompt {
    flex: 1;
    margin: 10px;
    text-align: center;
}

.hostGame {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
  }
  
  .podium {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .podium-item {
    text-align: center;
    margin: 0 15px;
  }
  
  .podium-1 h2{
    font-weight: bold;
    color: gold !important;
  }
  
  .podium-2 h2{
    color: silver !important;
  }
  
  .podium-3 h2{
    color: #cd7f32 !important; /* Bronze */
  }
  
  .player-list {
    width: 100%;
    max-width: 400px;
  }
  
  .player-item {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .player-item:nth-child(even) {
    background-color: #f9f9f9;
  }
  